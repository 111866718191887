.contact {
    @apply bg-violet pt-12 pb-12 relative;

    @screen md {
        @apply pb-20;
    }

    @screen lg {
        @apply pt-16 pb-24;
    }

    &__title {
        @apply flex items-center mb-12;

        @screen md {
            @apply mb-16;
        }

        @screen lg {
            @apply mb-20;
        }
    }

    &__subtitle {
        font-size: 1.5rem;
        padding-bottom: 2rem;

        @screen md {
            font-size: 1.75rem;
        }
    }

    &__number {
        @apply text-violet absolute w-full;
        left: 0;
        top: 0;
        margin-top: -84px;

        @screen lg {
            margin-top: -100px;
        }
    }

    &__socials {
        display: flex;
        align-items: center;
        margin-top: 1.5rem;

        svg {
            height: 40px;
            width: 40px;

            path {
                // fill: white;
                height: 40px;
                width: 40px;
            }
        }

        a {
            margin-right: 1rem;
        }
    }
}