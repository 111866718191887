.font-heading-1 {
    @apply font-miriam font-bold;
    font-size: 2.5rem;
    line-height: 100%;

    @screen md {
        font-size: 4rem;
    }
}

.font-heading-2 {
    @apply font-miriam font-bold;
    font-size: 2rem;
    line-height: 100%;

    @screen md {
        font-size: 2.5rem;
    }

    @screen lg {
        font-size: 3rem;
    }
}

.font-heading-3 {
    @apply font-raleway;
    font-size: 1.75rem;
    line-height: 100%;

    @screen md {
        font-size: 2.25rem;
    }
}

.font-heading-4 {
    @apply font-miriam font-bold;
    font-size: 1.75rem;
    line-height: 100%;

    @screen md {
        font-size: 2.25rem;
    }
}

.font-number {
    @apply font-miriam font-bold;
    font-size: 5rem;

    @screen lg {
        font-size: 6rem;
    }
}

.font-year {
    @apply font-raleway font-bold;
    font-size: 1.25rem;

    @screen md {
        font-size: 1.375rem;
    }
}

.font-corps {
    @apply font-raleway;
    font-size: 1rem;

    @screen md {
        font-size: 1.125rem;
    }
}

.font-button {
    @apply font-raleway uppercase;
    font-size: 1rem;
}