$base: '../assets/fonts/';

@font-face {
    font-family: 'Miriam Libre';
    src: url('#{$base}MiriamLibre-Bold.woff2') format('woff2'),
        url('#{$base}MiriamLibre-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Miriam Libre';
    src: url('#{$base}MiriamLibre-Regular.woff2') format('woff2'),
        url('#{$base}MiriamLibre-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Raleway';
    src: url('#{$base}Raleway-Bold.woff2') format('woff2'),
        url('#{$base}Raleway-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Raleway';
    src: url('#{$base}Raleway-Regular.woff2') format('woff2'),
        url('#{$base}Raleway-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Raleway';
    src: url('#{$base}Raleway-Thin.woff2') format('woff2'),
        url('#{$base}Raleway-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}