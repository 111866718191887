.presentation {
    @apply mt-16 relative;

    @screen md {
        @apply mt-24
    }

    @screen lg {
        @apply mt-36;
    }

    &__pic {
        @apply mt-20;

        @screen md {
            @apply mb-24;
        }

        @screen lg {
            @apply mb-36;
        }
    }

    &__background {
        @apply bg-violet absolute w-full;
        height: 70%;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
    }

    &__title {
        @apply flex items-center;
    }

    &__image {
        background-image: url('../assets/images/picture.jpg');
        background-size: cover;
        background-position: top center;
        background-repeat: no-repeat;
        height: 500px;
        width: 100%;

        &__container {
            position: relative;
        }
    }

    &__filter {
        background: linear-gradient(180deg, rgba(145, 109, 213, 0) 0%, #916DD5 86.46%);
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }

    &__text {
        @apply flex flex-col justify-center h-full;
    }

    &__content {
        @apply bg-violet py-12 w-full;
        margin-top: -3rem;

        @screen md {
            @apply absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            margin-top: 0;
        }
    }

    &__number {
        @apply text-violet absolute w-full;
        left: 0;
        top: 0;
        margin-top: -84px;

        @screen lg {
            margin-top: -100px;
        }
    }
}