.swiper {
    width: 100%;
    height: 100%;
}

.swiper-button-prev {
    position: absolute;
    left: 0;
    top: 50%;
    z-index: 1;
    transform: translateY(-50%);
    cursor: pointer;

    svg {
        height: 40px;
        width: 40px;
    }
}

.swiper-button-next {
    position: absolute;
    right: 0.25%;
    top: 50%;
    z-index: 1;
    transform: translateY(-50%);
    cursor: pointer;

    svg {
        height: 40px;
        width: 40px;
    }
}

.swiper-slide {
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
}