.heading {
    height: 95vh;
    position: relative;

    @screen md {
        height: 95vh;
    }

    @screen lg {
        height: 100vh;
    }

    &__gradient {
        background: linear-gradient(180deg, #916DD5 0%, #3E206D 63.54%, #29282D 87.5%);
        height: 100%;
        width: 50%;
        position: absolute;
        z-index: 1;

        @screen md {
            width: 45%;
        }
    }

    &__menu {
        font-size: 1.375rem;

        li {
            @apply mb-4 cursor-pointer;

            &:last-of-type {
                @apply mb-0 bg-violet w-fit;
                padding: 6px 16px 5px;
            }
        }
    }
}