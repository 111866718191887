@tailwind base;
@tailwind components;
@tailwind utilities;

@import "typography";
@import "fonts";
@import "heading";
@import "presentation";
@import "projects";
@import "contact";
@import "single_project";
@import "swiper";

body {
    @apply bg-black text-white font-raleway;
}

.container {
    margin: 0 auto;
    width: 100%;
    max-width: 1440px;
  
    @media (max-width: calc(1440px + 10px) ) {
      max-width: calc(100% - 2.5rem);
    }
}

.title_line {
    height: 1px;
    width: 50px;
    margin-right: 1rem;

    @screen md {
        width: 100px;
    }

    span {
        @apply bg-white block;
        width: 0;
        height: 100%;
    }
}