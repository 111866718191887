.projects {
    @apply mb-24 mt-16 relative;

    @screen md {
        @apply mt-0 mb-40;
    }

    @screen lg {
        @apply mb-64;
    }

    &__title {
        @apply flex items-center mb-20;
    }

    &__grid {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 1.25rem;

        @screen md {
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: repeat(4, 250px);
        }
    }

    &__item {
        @apply bg-violet relative;
        height: 300px;

        @screen md {
            @apply h-full;
        }
    }

    &__image {
        height: 100%;
        width: 100%;
        background-size: cover;
        background-position: top center;
        background-repeat: no-repeat;
    }

    &__filter {
        background: linear-gradient(180deg, rgba(145, 109, 213, 0) 0%, #916DD5 86.46%);
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }

    &__button {
        position: absolute;
        bottom: 50px;
        left: 50%;
        transform: translateX(-50%);
        cursor: pointer;

        &:hover {
            transform: rotate(180deg) translate(22.5px, 0);
            transform-origin: center center;
            transition: transform .3s ease-in-out;
        }
    }

    &__number {
        @apply text-violet absolute w-full;
        left: 0;
        top: 0;
        margin-top: 26px;
        color: #000;

        @screen md {
            margin-top: 35px;
        }

        @screen lg {
            margin-top: 26px;
        }
    }

    .proj-1 {
        @screen md {
            grid-area: 1 / 1 / 4 / 2;
        }
    }

    .proj-2 {
        @screen md {
            grid-area: 1 / 2 / 3 / 3;
        }
    }

    .proj-3 {
        @screen md {
            grid-area: 1 / 3 / 2 / 4;
        }
    }

    .proj-4 {
        @screen md {
            grid-area: 4 / 1 / 5 / 2;
        }
    }

    .proj-5 {
        @screen md {
            grid-area: 3 / 2 / 5 / 3;
        }
    }

    .proj-6 {
        @screen md {
            grid-area: 2 / 3 / 5 / 4;
        }
    }
}