.single_filter {
    @apply bg-black;
    display: none;
    opacity: 70%;
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 90;
}

.single_project {
    @apply items-center justify-center;
    background: transparent;
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 99;
    display: none;

    &__container {
        @apply bg-white flex;
        height: 80vh;
        width: 80%;
        flex-direction: column;

        @screen md {
            flex-direction: row;
        }
    }

    &__slider {
        width: 100%;
        height: 100%;

        @screen md {
            width: 50%;
        }
    }

    &__content {
        @apply bg-black p-4;
        width: 100%;
        height: 100%;
        overflow: scroll;
        overflow-x: hidden;

        &::-webkit-scrollbar {
            background-color: transparent;
            width: 7px;
        }

        &::-webkit-scrollbar-thumb {
            @apply bg-violet;
            border-radius: 50px;
        }

        @screen md {
            @apply p-8;
            width: 50%;
        }
    }

    &__close {
        width: 35px;
        height: 35px;
        cursor: pointer;

        @screen md {
            width: 42px;
            height: 42px;
        }

        path {
            fill: #FDFFFC;
        }
    }

    &__link {
        @apply bg-violet font-bold px-3 py-1 mt-8 border border-violet;
        display: table;
        transition: background-color .2s ease-out;

        &:hover {
            @apply text-violet bg-black;
        }
    }
}